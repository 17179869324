import React from "react";
import { Link } from "react-router-dom";
import { capitalize } from "../utils/capitalize";

// const getCustomPadding = (index) => {
//   if (window.innerWidth < 760) return {};
//   const isMid = (index - 1) % 3 === 0;
//   if (isMid) {
//     return { padding: "0 0.75rem" };
//   }
//   return {};
// };

export const ListItem = ({ data, index }) => {
  return (
    <div className="list-item">
      <Link to={`/${data.category}/${data.subcategory}/${data.slug}.html`}>
        <img src={data.thumbnail} alt="alt" loading="lazy" />
      </Link>
      <span>{data.title}</span>
      <Link
        className="list-item-subcategory"
        to={`/${data.category}/${data.subcategory}/`}
      >
        {capitalize(data.subcategory)}
      </Link>
    </div>
  );
};
