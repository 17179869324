import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Hamburger from "hamburger-react";
import { DESIGN, PHOTOGRAPHY } from "../data/constants";
import { useRouting } from "../utils/useRouting";
import { capitalize } from "../utils/capitalize";
import { useSize } from "../utils/useSize";

export const Header = () => {
  const [expanded, setExpanded] = useState(window.scrollY === 0);
  const [category, subcategory, slug] = useRouting();
  const [isOpen, setOpen] = useState(false);
  const headerRef = useRef(null);
  const size = useSize(headerRef);

  const handleScroll = () => {
    if (window.scrollY > 0 && expanded) {
      setExpanded(false);
    }
    if (window.scrollY === 0 && !expanded) {
      setExpanded(true);
    }
  };

  useEffect(() => {
    if (category === "contact.html") {
      return setExpanded(false);
    }
    document.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => document.removeEventListener("scroll", handleScroll);
  }, [expanded, category]);

  const renderSubcategory = () => {
    if (!subcategory || slug) return null;
    return (
      <>
        <span style={{ padding: "0 1rem" }}>/</span>
        <span>{capitalize(subcategory)}</span>
      </>
    );
  };

  const handleMobileClick = () => {
    setOpen(false);
  };

  return (
    <>
      <header ref={headerRef} className={expanded ? "expanded" : "collapsed"}>
        <h1>
          <Link to="/">ANA MILETIĆ</Link>
          {renderSubcategory()}
        </h1>
        <div className="hamburger-wrap">
          <Hamburger toggled={isOpen} toggle={setOpen} />
        </div>
        <div className="navigation-desktop">
          <nav>
            <Link
              to="/photography/"
              className={category === PHOTOGRAPHY ? "bold" : ""}
            >
              PHOTOGRAPHY
            </Link>
            <span>/</span>
            <Link to="/design/" className={category === DESIGN ? "bold" : ""}>
              DESIGN
            </Link>
          </nav>
        </div>
        {!expanded && (
          <div className="navigation-desktop">
            <Link
              to="/contact.html"
              className={
                category === "contact.html"
                  ? "contact-link bold"
                  : "contact-link"
              }
            >
              CONTACT
            </Link>{" "}
          </div>
        )}
      </header>
      {isOpen && (
        <div
          className="navigation-mobile"
          style={{ marginTop: (size || {}).height || 0 }}
        >
          <nav>
            <Link
              to="/photography/"
              onClick={handleMobileClick}
              className={category === PHOTOGRAPHY ? "bold" : ""}
            >
              PHOTOGRAPHY
            </Link>
            <Link
              to="/design/"
              onClick={handleMobileClick}
              className={category === DESIGN ? "bold" : ""}
            >
              DESIGN
            </Link>
            <Link
              to="/contact/"
              onClick={handleMobileClick}
              className={
                category === "contact" ? "contact-link bold" : "contact-link"
              }
            >
              CONTACT
            </Link>
          </nav>
        </div>
      )}
    </>
  );
};
