import React, { useEffect, useState } from "react";
import { useRouting } from "../utils/useRouting";
import { DATA } from "../data/data";
import { capitalize } from "../utils/capitalize";
import Plus from "../components/plus";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export const DetailPage = () => {
  const [category, subcategory, slug] = useRouting();
  const [data, setData] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [imageHovered, setImageHovered] = useState(false);

  useEffect(() => {
    const detailData = DATA.find(
      (obj) =>
        obj.slug === slug &&
        obj.category === category &&
        obj.subcategory === subcategory
    );
    setData(detailData);
  }, [slug]);

  if (!data) return null;
  const { images } = data;

  const handlePlusClick = () => setDropdownOpen((prevState) => !prevState);

  const toggleGallery = () => {
    if (window.innerWidth < 760) return;
    setGalleryOpen((prevState) => !prevState);
  };

  const handleImageHover = (idx) => () => {
    setImageHovered(idx);
  };

  const openGallery = (idx) => () => {
    if (typeof idx === "number") setPhotoIndex(idx);
    toggleGallery();
  };

  const renderImages = () =>
    data.images.map((obj, idx) => (
      <div key={`${obj.slug}${idx}`} className="detail-image-wrapper">
        {obj.text && (
          <span
            className={`detail-image-text ${
              imageHovered === idx ? "active" : ""
            }`}
          >
            {obj.text}
          </span>
        )}
        <img
          key={obj.src}
          src={obj.src}
          alt=""
          onClick={openGallery(idx)}
          onMouseEnter={handleImageHover(idx)}
          onMouseLeave={handleImageHover(false)}
        />
      </div>
    ));

  const renderExtra = (heading, txt) => {
    if (!txt) return null;
    return (
      <div className="detail-dropdown-entry">
        <div>{heading}</div>
        <div>{txt}</div>
      </div>
    );
  };

  return (
    <div className="detail-wrap">
      <div className="detail-dropdown">
        <div className="detail-dropdown-heading">
          <div>{data.title}</div>
          <div>{capitalize(data.subcategory)}</div>
        </div>
        <Plus handlePlusClick={handlePlusClick} open={dropdownOpen} />
      </div>
      <div
        className={`detail-dropdown-text ${dropdownOpen ? "opened" : "closed"}`}
      >
        <span
          className="detail-dropdown-info-text"
          dangerouslySetInnerHTML={{ __html: data.text }}
        />
        {renderExtra("Year", data.year)}
        {renderExtra("Model", data.model)}
        {renderExtra("Client", data.client)}
        {renderExtra("Collaborator", data.collaborator)}
      </div>
      <div className={`detail-images columns`}>{renderImages()}</div>
      {galleryOpen && (
        <Lightbox
          imagePadding={75}
          mainSrc={images[photoIndex].src}
          nextSrc={images[(photoIndex + 1) % images.length].src}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
          onCloseRequest={toggleGallery}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (prevState) => (prevState + images.length - 1) % images.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((prevState) => (prevState + 1) % images.length)
          }
        />
      )}
    </div>
  );
};
