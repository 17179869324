import React, { useEffect, useState } from "react";
import { useRouting } from "../utils/useRouting";
import { DATA } from "../data/data";
import { ListItem } from "../components/list-item";

export const ListPage = () => {
  const [category, subcategory] = useRouting();
  const [data, setData] = useState(null);

  useEffect(() => {
    let newData = DATA.filter((obj) => obj.category === category);
    if (subcategory) {
      newData = newData.filter((obj) => obj.subcategory === subcategory);
    }
    setData(newData);
  }, [category, subcategory]);

  if (!data) return null;

  return (
    <section className="list-wrap">
      {data.map((obj, idx) => (
        <ListItem key={obj.slug} data={obj} index={idx} />
      ))}
    </section>
  );
};
