import React from "react";

export const ContactPage = () => {
  return (
    <div className="contact-wrap">
      <div>
        <img src="/img/profil.png"/>
      </div>
      <div className="contact-info">
        <div className="contact-block">
          <div>About</div>
          <div style={{ marginBottom: "1.5rem" }}>
            I'm a freelance photographer with focus on nudes and landscapes,
            striving to combine both.
          </div>
          <div>
            25 year old, based in Split, Croatia, working on my Masters degree
            in Design of Visual Communications.
          </div>
        </div>
        <div className="contact-block">
          <div>E-mail</div>
          <div>ana.miletic96@gmail.com</div>
        </div>
        <div className="contact-block">
          <div>Exhibitions</div>
          <div>
            <a href="https://web.facebook.com/events/477110916685063/?ref=newsfeed">Produkcija 2021. / MKC Split 2021.</a>
            <a href="http://fotoklubrijeka.hr/izlozba-fotografkinje-2021/">Fotografkinje 2021. / Fotoklub Rijeka 2021.</a>
            <a href="https://arteist.hr/gola-tijela-i-jugo-namjestaj-aktovi-ane-miletic-pricaju-o-dehumanizaciji-zene/">Ana Miletić: Karantina / Fotoklub Split 2021.</a>
            <a href="https://slobodnadalmacija.hr/kultura/ostalo/simbolicna-kronika-splita-koje-nema-na-kartolinama-izlozba-studenata-i-alumnija-umas-a-u-gradskoj-knjiznici-marka-marulica-1045734">
              Split u planovima / Gradska knjižnica Marko Marulić, Split 2020. </a>
            <a href="https://web.facebook.com/events/195929061147536/?_rdc=1&_rdr">Emocija/Asocijacija / Galerija Beck, Zagreb 2018.</a>
          </div>
        </div>
      </div>
    </div>
  );
};
