import React from "react";

const Plus = ({ open, handlePlusClick }) => {
  return (
    <div className="plus-wrapper" onClick={handlePlusClick}>
      <div className={`circle-plus ${open ? "opened" : "closed"}`}>
        <div className="circle">
          <div className="horizontal" />
          <div className="vertical" />
        </div>
      </div>
    </div>
  );
};

export default Plus;
