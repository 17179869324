import "./App.css";
import { Route, Switch } from "react-router-dom";
import { ListPage } from "./pages/list";
import { Header } from "./components/header";
import { DetailPage } from "./pages/detail";
import { ContactPage } from "./pages/contact";

function App() {
  return (
    <div className="App">
      <Header />
      <Switch>
        <Route exact path="/contact.html" component={ContactPage} />
        <Route exact path="/" component={ListPage} />
        <Route path="/:category/:subcategory/:item" component={DetailPage} />
        <Route path="/:category/:subcategory/" component={ListPage} />
        <Route path="/:category/" component={ListPage} />
      </Switch>
    </div>
  );
}

export default App;
