export const PHOTOGRAPHY = "photography";
export const DESIGN = "design";

export const ROUTE_MAPPING = {
    [PHOTOGRAPHY]: {
        COMMERCIAL: "commercial",
        PERSONAL: "personal",
    
    },
    [DESIGN]: {
        VISUAL_IDENTITY: "visual-identity",
        PACKAGING: "packaging",
        ILLUSTRATION: "illustration",
        MISCELLANEOUS: "miscellaneous"
    }
};