import { useLocation } from "react-router-dom";
import { PHOTOGRAPHY } from "../data/constants";
import { useEffect, useState } from "react";

export const useRouting = () => {
  const { pathname } = useLocation();
  const [segments, setSegments] = useState([]);

  useEffect(() => {
    const segments = pathname.split("/").filter((_) => _);
    const category = segments[0] || PHOTOGRAPHY;
    const subcategory = segments[1];
    const slug = (segments[2] || "").replace(".html", "");
    setSegments([category, subcategory, slug]);
  }, [pathname]);

  return segments;
};
